import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Veristone',
    useCase: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT',
    description: `Veristone Capital is a hard money lender based in Kirkland Washington. They approached me to help them re-brand their company. They had engaged multiple designers and creatives in the past, yet this had not produced the outcome they desired. The drive behind Veristones business is taking the confusion out of the lending process. I designed the V to reflect this mentality. Taking 3 lines and merging it into 1 shape, this is representing taking a complex line of thought and merging it into a simple thought. We updated the color palette to be fresh and clean, adding a pop of electric green to differentiate themselves from others in their market. This is a cool company with a unique approach to helping clients fund their ideas and visions of the future.`,
    link: 'veristonecapital.com',
  },
  caseId: 'veristone',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
